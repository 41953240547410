import React from "react";
import { H3 } from "../text";
import * as Styles from "./application-type-tab.styles";

export const ApplicationTypeTab = ({ fullyBooked, cost, additionalCosts }) => {
  const spacesAvailableText = fullyBooked
    ? "No spaces available"
    : "Spaces available";

  const additionalCostComponents =
    additionalCosts &&
    additionalCosts.map((cost, i) => (
      <li style={{ listStyle: "none" }} key={i}>
        + {cost}
      </li>
    ));

  return (
    <Styles.ContentWrapperWithPadding>
      <H3>Availability</H3>
      <p>{spacesAvailableText}</p>
      <H3>Cost</H3>
      <div>
        <p>
          {cost || "No fee"} <br />
        </p>
        <ul className="font-small">{additionalCostComponents}</ul>
      </div>
    </Styles.ContentWrapperWithPadding>
  );
};

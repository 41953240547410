import styled from "styled-components";
import css from "@styled-system/css";
import { Plus } from "../icons/plus";

export const Wrapper = styled.div`
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transform: translateZ(0);
  width: 100%;

  ${css({
    backgroundColor: "background.body",
    boxShadow: "softGlow",
    paddingX: [2],
    paddingY: [1],
  })}
`;

export const Header = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  transition: background-color 500ms;
  width: 100%;

  ${({ isOpen }) =>
    css({
      backgroundColor: isOpen ? "background.tertiary" : "background.body",
      paddingY: [2],
    })}
`;

export const Label = styled.div`
  ${css({
    fontFamily: "heading",
    fontSize: [2],
    fontWeight: "bold",
  })}
`;

export const Content = styled.div`
  overflow: auto;
  transform: translateZ(0);
  transition: max-height 500ms, opacity 500ms, padding 500ms;
  width: 100%;

  ${({ isOpen }) =>
    css({
      maxHeight: isOpen ? "500px" : "0px",
      opacity: isOpen ? "1" : "0",
    })}
`;

export const PlusIcon = styled(Plus)`
  height: 30px;
  width: 30px;
  fill: ${(props) => props.theme.colors.foreground.link};
  transform: ${(props) => (props.isOpen ? "rotate(135deg)" : "rotate(0deg)")};
  transition: transform 400ms;
`;

import React from "react";
import * as Styles from "./trip-card.styles";

export const TripCard = (props) => (
  <Styles.StyledBox>
    <Styles.StyledLink to={props.to}>
      <Styles.ImageWrapper>
        <Styles.Image imageSource={props.imageSource} />
      </Styles.ImageWrapper>
      <Styles.ContentWrapper>
        <Styles.StyledSubtitle>{props.location}</Styles.StyledSubtitle>
        <Styles.Title>{props.title}</Styles.Title>
      </Styles.ContentWrapper>
    </Styles.StyledLink>
  </Styles.StyledBox>
);

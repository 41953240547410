import styled from "styled-components";
import css from "@styled-system/css";
import { Column } from "../../components/column";

export const Row = styled.div`
  display: flex;
  ${css({
    flexDirection: ["column", "column", "row"],
  })}
`;

export const LeftColumn = styled(Column)`
  ${css({ width: ["100%", "100%", "66.66%"] })}
`;

export const RightColumn = styled(Column)`
  ${css({ width: ["100%", "100%", "33.33%"] })}
`;

export const PreformattedContentWrapper = styled.div`
  white-space: pre-wrap;
`;

export const TabContent = styled.div`
  padding: 50px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
`;

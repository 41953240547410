import React from "react";

export const Plus = (props) => {
  return (
    <svg
      className={props.className}
      height={props.height}
      viewBox="0 0 100 100"
      width={props.width}
    >
      <g transform="matrix(1,0,0,1,-5.2225,-0.556066)">
        <path d="M60.445,10.839C60.445,7.957 58.105,5.617 55.223,5.617L55.222,5.617C52.34,5.617 50,7.957 50,10.839L50,90.273C50,93.155 52.34,95.496 55.222,95.496L55.223,95.496C58.105,95.496 60.445,93.155 60.445,90.273L60.445,10.839Z" />
      </g>
      <g transform="matrix(6.12323e-17,1,-1,6.12323e-17,100.556,-5.2225)">
        <path d="M60.445,10.839C60.445,7.957 58.105,5.617 55.223,5.617L55.222,5.617C52.34,5.617 50,7.957 50,10.839L50,90.273C50,93.155 52.34,95.496 55.222,95.496L55.223,95.496C58.105,95.496 60.445,93.155 60.445,90.273L60.445,10.839Z" />
      </g>
    </svg>
  );
};

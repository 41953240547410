import styled from "styled-components";
import css from "@styled-system/css";

export const Wrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  ${css({
    backgroundColor: "background.primary",
    color: "foreground.primary",
    boxShadow: "softGlow",
  })}

  display: ${(props) =>
    props.orientation === "horizontal" ? "flex" : "block"};
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.orientation === "horizontal" ? "column" : "row"};
`;

export const TabLink = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  text-align: center;
  transition: all 250ms ease;

  ${css({
    fontSize: ["0.7rem", "1rem"],
    padding: [1, 2],
    fontFamily: "heading",
    fontWeight: "semiBold",
  })}

  span {
    border-bottom: 2px solid;

    ${({ active }) =>
      active
        ? css({
            borderBottomColor: "tab.active.border",
          })
        : css({
            borderBottomColor: "tab.inactive.border",
          })}
  }

  :hover {
    span {
      ${({ active }) =>
        !active &&
        css({
          borderBottomColor: "tab.inactive.borderHover",
        })}
    }
  }

  ${({ active }) =>
    active
      ? css({
          backgroundColor: "tab.active.background",
          color: "tab.active.foreground",
          "&:hover": {
            backgroundColor: "tab.active.background",
            color: "tab.active.foreground",
          },
        })
      : css({
          backgroundColor: "tab.inactive.background",
          color: "tab.inactive.foreground",
          "&:hover": {
            backgroundColor: "tab.inactive.hover",
            color: "tab.inactive.foreground",
          },
        })}
`;

export const TabContent = styled.div`
  width: 100%;
  ${({ active }) => `display: ${active ? "block" : "none"};`}
`;

import React from "react";
import styled from "styled-components";
import { H4 } from "../text";
import { formatIsoString } from "../../utils/dates";
import { Arrow } from "../icons/arrow";

const dateFormat = "dd/MM/yyyy";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TripsContainer = styled.div`
  flex: 1;
  max-width: 400px;
`;

const TitleWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #04627b;
`;

const CentreWrapper = styled.div`
  flex: 1;
  text-align: center;
`;

const Date = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #04627b;
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const StyledArrow = styled(Arrow)`
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
`;

export const ProjectDates = ({ trips }) => (
  <>
    <H4>Dates</H4>
    <Wrapper>
      <TripsContainer>
        {trips.length === 1 && !trips[0].endDate ? (
          <div>
            Dates available starting{" "}
            {formatIsoString(trips[0].startDate, dateFormat)}
          </div>
        ) : (
          <>
            <TitleWrapper>
              <CentreWrapper>
                <strong>Start date</strong>
              </CentreWrapper>

              <CentreWrapper>
                <strong>End date</strong>
              </CentreWrapper>
            </TitleWrapper>
            {trips.map((trip, i) => (
              <Date key={i}>
                <CentreWrapper>
                  {formatIsoString(trip.startDate, dateFormat)}
                </CentreWrapper>
                <ArrowWrapper>
                  <StyledArrow colour="foreground.link" />
                </ArrowWrapper>
                <CentreWrapper>
                  {formatIsoString(trip.endDate, dateFormat)}
                </CentreWrapper>
              </Date>
            ))}
          </>
        )}
      </TripsContainer>
    </Wrapper>
  </>
);

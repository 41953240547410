import Frisbee from "frisbee";

const api = new Frisbee({
  baseURI: `${process.env.GATSBY_PORTAL_API_URL}/channels/wvs-india-site`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const getTrainingProjects = async () => {
  try {
    const response = await api.get("?type=course", { mode: "cors" });

    return { success: true, projects: response.body.projects };
  } catch (error) {
    return {
      success: false,
      error: "Something went wrong while trying to fetch trips",
    };
  }
};

export const getVolunteerProjects = async () => {
  try {
    const response = await api.get("?type=campaign,experience,research", {
      mode: "cors",
    });

    return { success: true, projects: response.body.projects };
  } catch (error) {
    return {
      success: false,
      error: "Something went wrong while trying to fetch trips",
    };
  }
};

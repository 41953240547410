import React, { useState } from "react";
import * as Styles from "./tab-box.styles";

export const TabBox = ({ variant, tabs, children }) => {
  const [activeTab, setActiveTab] = useState(0);

  const orientation = tabs.length > 1 && variant ? variant : "vertical";

  const tabTitles = tabs.map((tab, i) => (
    <Styles.TabLink
      active={i === activeTab}
      key={i}
      onClick={() => setActiveTab(i)}
      orientation={orientation}
    >
      <span>{tab}</span>
    </Styles.TabLink>
  ));

  return (
    <Styles.Wrapper orientation={orientation}>
      <Styles.TabsWrapper orientation={orientation}>
        {tabTitles}
      </Styles.TabsWrapper>
      {children.map((item, i) => (
        <Styles.TabContent active={i === activeTab} key={i}>
          {item}
        </Styles.TabContent>
      ))}
    </Styles.Wrapper>
  );
};

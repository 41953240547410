import styled from "styled-components";

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Spinner = styled.i`
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  width: 1em;
  height: 1em;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner 0.6s linear infinite;
  margin-bottom: 50px;
  opacity: 0.5;
  width: 50px;
  height: 50px;
`;

import React, { useState } from "react";
import * as Styles from "./styles";

export const Accordion = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  const canToggle = !props.keepOpen;

  return (
    <Styles.Wrapper>
      <Styles.Header
        onClick={() => {
          if (canToggle) {
            setIsOpen(!isOpen);
          }
        }}
        canToggle={canToggle}
      >
        <Styles.Label>{props.label}</Styles.Label>
        {canToggle && <Styles.PlusIcon isOpen={isOpen} />}
      </Styles.Header>
      <Styles.Content isOpen={isOpen}>{props.children}</Styles.Content>
    </Styles.Wrapper>
  );
};

import styled from "styled-components";
import css from "@styled-system/css";
import { Label } from "../text";
import { Link } from "gatsby";

export const StyledLink = styled(Link)`
  text-decoration: inherit;
  color: inherit;
  display: inline;
`;

export const StyledBox = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;

  ${css({
    backgroundColor: "background.primary",
    borderRadius: [2],
    boxShadow: "glow",
  })}
`;

export const ImageWrapper = styled.div`
  overflow: hidden;
`;

export const Image = styled.div`
  background-size: cover;
  background-position: center center;
  background-image: url(${(props) => props.imageSource});
  padding-bottom: 56.25%; // 16/9
`;

export const ContentWrapper = styled.div`
  padding: 15px 20px;
  height: 120px;

  ${css({
    backgroundColor: "background.primary",
  })}
`;

export const StyledSubtitle = styled(Label)`
  text-transform: uppercase;
  font-style: initial;
  font-weight: 600;
`;

export const Title = styled.p`
  ${css({
    fontFamily: "heading",
    fontSize: [2],
    fontWeight: "semiBold",
  })}
  line-height: 1.2;
  margin: 0;
`;

import styled from "styled-components";
import css from "@styled-system/css";

export const MainImage = styled.div`
  width: 100%;

  img {
    border-radius: 10px;
    width: 100%;
  }
`;

export const ThumbnailWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0 0 0;
  width: 100%;
`;

export const Thumbnail = styled.button`
  background: ${({ image }) => `url(${image})`};
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  height: 40px;
  ${css({
    height: ["40px", "80px"],
    width: ["40px", "80px"],
    marginRight: [1],
    marginBottom: [1],
  })}

  width: 40px;

  ${({ active }) =>
    active &&
    css({
      borderColor: "border.active",
      borderWidth: "2px",
      borderStyle: "solid",
    })}
`;

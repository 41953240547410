import HeroImage from "../../images/pages/homepage/hero.jpg";
import React, { useEffect, useState } from "react";
import { ContentLinks } from "../../components/content-links";
import { H1, Text } from "../../components/text";
import { Hero } from "../../components/hero";
import { Layout } from "../../components/layout";
import { LoadingPage } from "../loading";
import { Seo } from "../../components/seo";
import { LinkButton } from "../../components/button";

export const NotFound = ({ location, buttonLink, buttonText, fallbackSEO }) => {
  const [isMount, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
  }, []);

  if (!isMount) {
    return <LoadingPage location={location} SEOComponent={fallbackSEO} />;
  } else {
    return (
      <Layout>
        <Seo lang="en" title="Page not found" />
        <Hero image={HeroImage} fullscreen>
          <H1>Page not found</H1>
          <Text>
            Sorry, but the page you were looking for could not be found.
          </Text>
          {buttonLink && buttonText ? (
            <LinkButton to={buttonLink}>{buttonText}</LinkButton>
          ) : (
            <LinkButton to="/">Back to homepage</LinkButton>
          )}
        </Hero>
        <ContentLinks />
      </Layout>
    );
  }
};

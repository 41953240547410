import React from "react";
import { Loading } from "../../components/loading";
import { Layout } from "../../components/layout";
import * as Styles from "./styles";

export const LoadingPage = ({ SEOComponent }) => {
  return (
    <Layout>
      {SEOComponent}
      <Styles.LoadingWrapper>
        <Loading />
      </Styles.LoadingWrapper>
    </Layout>
  );
};

import React from "react";
import { Router } from "@reach/router";
import { useProjects } from "../hooks/useProjects";
import { Courses } from "../page-components/courses";
import { Project } from "../page-components/project";
import NotFound from "./404";
import { LoadingPage } from "../page-components/loading";
import { Seo } from "../components/seo";
import SEOImage from "../images/wvs-india-seo.jpg";

const Index = ({ location }) => {
  const { projects, isLoading } = useProjects("training");

  const SEOComponent = (
    <Seo lang="en" title="Train with us // Programmes" image={SEOImage} />
  );

  if (isLoading && location.pathname !== "/courses") {
    return <LoadingPage SEOComponent={SEOComponent} />;
  }

  return (
    <Router>
      <Courses path="/courses" courses={projects} isLoading={isLoading} />
      {projects &&
        projects.map((project) => (
          <Project key={project.id} path={project.path} project={project} />
        ))}
      <NotFound
        default
        buttonLink="/courses"
        buttonText="View available courses"
        fallbackSEO={SEOComponent}
      />
    </Router>
  );
};

export default Index;

import React, { useState } from "react";
import * as Styles from "./image-gallery.styles";

export const ImageGallery = ({ images, alt }) => {
  const [activeImage, setActiveImage] = useState(0);

  const mainImage = <img alt={alt} src={images[activeImage]} />;

  return (
    <>
      <Styles.MainImage>{mainImage}</Styles.MainImage>
      <Styles.ThumbnailWrapper>
        {images.map((image, i) => (
          <Styles.Thumbnail
            active={i === activeImage}
            aria-label="Select this image"
            key={i}
            onClick={() => setActiveImage(i)}
            image={image}
          />
        ))}
      </Styles.ThumbnailWrapper>
    </>
  );
};
